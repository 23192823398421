<template>
  <div>
    <a-steps :current="current">
      <a-step v-for="item in steps" :key="item.title" :title="item.title">
        <span slot="description"> {{item.description}}</span>
      </a-step>
    </a-steps>
    <div class="steps-content">
      <keep-alive>
        <StepOne ref="stepOne" :activityInfo="form.activityInformation" :isDisabled="isDisabled" v-if="current === 0" />
        <StepTwo ref="stepTwo" :activityInfo="form.activityInformation" :isDisabled="isDisabled" v-if="current === 1"/>
        <StepThree ref="stepThree" :questionInfo="form.listActivityQuestionInfo" :isDisabled="isDisabled" v-if="current === 2"/>
      </keep-alive>
    </div>
    <div class="steps-action">
      <a-button v-if="current === 0" type="primary" style="margin-right: 8px" @click="goBack">
        返回
      </a-button>
      <a-button v-if="current > 0" style="margin-right: 8px" @click="prev">
        上一步
      </a-button>
      <a-button v-if="current < 2 && !isNotApplyStatus" style="margin-left: 8px" type="primary" @click="next">
        下一步
      </a-button>
      <a-button
          v-if="current === 2 || isNotApplyStatus"
          type="primary"
          :disabled="isDisabled"
          @click="onSubmit()"
      >
        提交
      </a-button>

    </div>
  </div>
</template>

<script>
import StepOne from './components/stepOne'
import StepTwo from "./components/stepTwo"
import StepThree from "./components/stepThree"
import { addActivity, findActivityById, updateActivity } from '../../../api/activityRegistration'
import moment from "moment";
export default {
  name: "addActivity",
  data() {
    return {
      current: 0,
      // 当前id
      id: '',
      // 是否需要报名状态
      isNotApplyStatus: false,
      isDisabled: false,
      steps: [
        {
          title: '步骤一',
          description: '活动信息'
        },
        {
          title: '步骤二',
          description: '活动设置'
        },
        {
          title: '步骤三',
          description: '活动信息维护'
        },
      ],

      form: {
        // 活动信息
        activityInformation: {},
        // 活动问题信息
        listActivityQuestionInfo: [],
        // 活动条件详情
        activityConditionDetailsPO: {}
      }
    };
  },
  components: {
    StepOne,
    StepTwo,
    StepThree
  },
  created() {
    if (this.$route.query.type === 'view' || this.$route.query.type === 'edit') {
      this.id = this.$route.query.id
      // 根据id查询详情
      this.findOneById()
    }
    if (this.$route.query.type === 'view') {
      this.isDisabled = true
    }
  },
  methods: {
    // 生成一个随机id，当前时间戳+ 0 - 1000的随机数
    createRandom() {
      const min = 1;
      const max = 1000;
      const randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
      return (moment().valueOf() + randomInt).toString();
    },
    findOneById() {
      findActivityById(this.id).then(res => {
        if (res.code === '200') {
          if (res.data.activityInformation.whetherRegistration === 1 && res.data.activityInformation.costType === 1) {
            res.data.activityInformation.consumptionPoints = 1;
          }
          // 删除id防止覆盖主键id
          if (res.data.activityConditionDetailsPO) {
            delete res.data.activityConditionDetailsPO.id
          }
          this.form.activityInformation = { ...res.data.activityInformation, ...res.data.activityConditionDetailsPO }
          res.data.listActivityQuestionInfo.map(item => {
            item.answerJson = JSON.parse(item.answerJson)
            if (item.userLimitJson === null) {
              item.userLimitJson = []
            }else {
              item.userLimitJson = JSON.parse(item.userLimitJson)
            }
            return item
          })
          const questions = JSON.parse(JSON.stringify(res.data.listActivityQuestionInfo))
          // 如果老数据中没有flagId，则随机生成一个flagId
          this.form.listActivityQuestionInfo = questions.map(item => {
            return {
              ...item,
              refQuestionFontId: item.refQuestionFontId ? item.refQuestionFontId : this.createRandom()
            }
          })
        }
      })
    },
    next() {
      if (this.current === 0) {
        this.$refs.stepOne.onSubmit()
      }
      if (this.current === 1) {
        this.$refs.stepTwo.onSubmit()
      }
    },
    // 完成第一步和第二步后的回调
    finishedToNext(data, step) {
      if (step === 1) {
        let projects = data.isShowProjects.map(item => {
          return  {
            id: item
          }
        })
        let userCrowds = data.userCrowds.map(item => {
          return {
            labelId: item
          }
        })
        this.form.activityInformation.showPlatform = 0
        this.form.activityInformation.activityMode = data.activityWay;
        this.form.activityInformation.whetherRegistration = data.isApply;
        this.form.activityInformation.title = data.activityTitle;
        this.form.activityInformation.shareRemark = data.shareDescribe;
        if (data.activityTime) {
          this.form.activityInformation.startTime = moment(data.activityTime[0]).format('YYYY-MM-DD HH:mm:ss');
          this.form.activityInformation.endTime = moment(data.activityTime[1]).format('YYYY-MM-DD HH:mm:ss');
        }
        if (data.applyTime) {
          this.form.activityInformation.registrationStartTime = moment(data.applyTime[0]).format('YYYY-MM-DD HH:mm:ss');
          this.form.activityInformation.registrationEndTime = moment(data.applyTime[1]).format('YYYY-MM-DD HH:mm:ss');
        } else {
          this.form.activityInformation.registrationStartTime = '';
          this.form.activityInformation.registrationEndTime = '';
        }
        this.form.activityInformation.activityPlace = data.activityAddress;
        this.form.activityInformation.peopleNumber = data.numberPersonLimit;
        this.form.activityInformation.activityDetails = data.activityDetail;
        this.form.activityInformation.wechatGroupErcode = data.applySuccessCrowdCode;
        this.form.activityInformation.projectList = projects;
        this.form.activityInformation.activityUserLabelList = userCrowds || [];
        this.form.activityInformation.coverMap = data.coverImg;
        this.form.activityInformation.shareImg = data.shareImg;
        this.form.activityInformation.activityBusinessRestrictList = data.activityBusinessRestrictList
        this.form.activityInformation.restrictAmount = data.restrictAmount * 100;
        this.form.activityInformation.displayPriority = data.displayPriority
        this.form.activityConditionDetailsPO = {
          accessLogState: data.accessLogState,
          passStartDate: data.dateTimePeriod && data.dateTimePeriod.length ? moment(data.dateTimePeriod[0]).format('YYYY-MM-DD') : '',
          passEndDate: data.dateTimePeriod && data.dateTimePeriod.length ? moment(data.dateTimePeriod[1]).format('YYYY-MM-DD') : '',
          passStartTime: data.passStartTime ? moment(data.passStartTime).format('HH:mm:ss') : '',
          passEndTime: data.passEndTime ? moment(data.passEndTime).format('HH:mm:ss') : '',
          popupCondition: data.popupCondition ? 1 : 0,
          popupId: data.popupId
        }
      }
      // 如果不需要报名，直接走提交数据的接口
      if (this.isNotApplyStatus) {
        this.onSubmitData()
        return;
      }
      if (step === 2) {
        this.form.activityInformation.costType = data.costsSet;
        this.form.activityInformation.consumptionPoints = data.consumeIntegralNum;
        this.form.activityInformation.showFree = data.showFree;
        this.form.activityInformation.verifyType = data.verifyWay;
        this.form.activityInformation.whetherOff = data.verifyJoin;
        this.form.activityInformation.whetherEnterprise = data.isEnterpriseAuth;
        this.form.activityInformation.remindTime = data.remindUserTime.toString();
        this.form.activityInformation.joinNum = data.joinNum
        this.form.activityConditionDetailsPO.verificationIntegralStatus = data.verificationIntegralStatus;
        if (data.verificationIntegralStatus === 1) {
          if (data.verificationRuleId && data.verificationRuleId.length === 16 && data.verificationSendIntegralTotal) {
            this.form.activityConditionDetailsPO.verificationRuleId = data.verificationRuleId;
            this.form.activityConditionDetailsPO.verificationSendIntegralTotal = data.verificationSendIntegralTotal;
          } else {
            this.$message.error('请输入有效积分ID')
            return;
          }
        }
      }
      this.current++
    },
    prev() {
      this.current--;
    },
    // 提交
    onSubmit() {
      if (this.isNotApplyStatus) {
        this.$refs.stepOne.onSubmit()
      } else {
        this.$refs.stepThree.handleSubmit()
      }
    },
    //
    finished(data) {
      let arr = []
      if ( data !== null && data !== undefined && data !== '') {
        data.forEach(item => {
          let newData = {
            questionContent: item.questionContent,
            leastWord: item.leastWord,
            mostWord: item.mostWord,
            questionType: item.questionType,
            whetherRequired: item.whetherRequired ? 1 : 0,
            whetherToShow:item.whetherToShow ? 1 : 0,
            answerJson: JSON.stringify(item.answerJson),
            answerType: item.answerType,
            imgAmount: item.imgAmount,
            userLimitJson: JSON.stringify(item.userLimitJson),
            mostSelect: item.mostSelect,
            questionLevelFlag: item.questionLevelFlag ? 1 : 0,
            refQuestionFlag: item.refQuestionFlag ? 1 : 0,
            refQuestionFontId: item.refQuestionFontId,
            refQuestionId: item.refQuestionId
          }
          arr.push(newData)
        })
      }
      this.form.listActivityQuestionInfo = arr
      // 去提交
      this.onSubmitData()
    },
    // 去提交数据
    onSubmitData() {
      // 如果是不需要报名，删除步骤二、步骤三的数据
      if (this.form.activityInformation.whetherRegistration === 0) {
        this.form.activityInformation.costType = '';
        this.form.activityInformation.showFree = ''
        this.form.activityInformation.consumptionPoints = '';
        this.form.activityInformation.verifyType = ''
        this.form.activityInformation.whetherOff = '';
        this.form.activityInformation.whetherEnterprise = '';
        this.form.activityInformation.remindTime = '';
        this.form.listActivityQuestionInfo = []
      }
      if (this.form.activityInformation.whetherRegistration === 1 && this.form.activityInformation.costType === 1) {
        this.form.activityInformation.consumptionPoints = 0;
      }
      if (this.$route.query.type === 'add') {
        addActivity(this.form).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功')
            this.$router.push({
              path: "/activityRegistration",
            });
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        updateActivity(this.form).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功')
            this.$router.push({
              path: "/activityRegistration",
            });
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    // 返回
    goBack() {
      this.$router.go(-1)
    },
    // 如果不需要报名，显示提交按钮并且隐藏下一步
    isNotApply(status) {
      this.isNotApplyStatus = status
    }
  },
}
</script>

<style scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
  text-align: center;
}
</style>
