<template>
  <div>
    <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="费用设置" :required="true" >
        <a-radio-group :disabled="isDisabled" v-model="form.costsSet">
          <a-radio :value="1">
            免费
          </a-radio>
          <a-radio :value="2">
            积分消耗
          </a-radio>
        </a-radio-group>
        <a-input-number :disabled="isDisabled" :min="1" v-if="form.costsSet === 2" v-model="form.consumeIntegralNum"/>
      </a-form-model-item>
      <a-form-model-item label="是否展示免费" v-if="this.form.costsSet === 1" :required="true" >
        <a-radio-group :disabled="isDisabled" v-model="form.showFree">
          <a-radio :value="1">
            是
          </a-radio>
          <a-radio :value="0">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="每人限制参与次数" prop="joinNum">
        <a-input-number :disabled="isDisabled" :precision="0" :min="1" :max="999" v-model="form.joinNum"/> 次
      </a-form-model-item>
      <a-form-model-item label='是否验证参与' :required="true" >
        <a-radio-group :disabled="isDisabled" v-model="form.verifyJoin">
          <a-radio :value="1">
            是
          </a-radio>
          <a-radio :value="0">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label='验证方式' v-if="form.verifyJoin !== 0" :required="true" >
        <a-radio-group v-model="form.verifyWay" :disabled="isDisabled || disabledVerifyWay">
          <a-radio :value="1">
            用户主扫
          </a-radio>
          <a-radio :value="0">
            用户被扫
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label='是否需要企业认证' :required="true" >
        <a-radio-group v-model="form.isEnterpriseAuth" :disabled="isDisabled">
          <a-radio :value="1">
            是
          </a-radio>
          <a-radio :value="0">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label='距离活动开始前短信提醒时间'>
        <a-checkbox-group :options="options" :disabled="isDisabled" v-model="form.remindUserTime"/>
      </a-form-model-item>
      <a-form-model-item label='是否发放积分' prop="verificationIntegralStatus">
        <a-radio-group :disabled="isDisabled" v-model="form.verificationIntegralStatus">
          <a-radio :value="1">
            是
          </a-radio>
          <a-radio :value="0">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="verificationRuleId" label="积分规则ID" prop="verificationRuleId" v-if="form.verificationIntegralStatus === 1">
        <div>
          <a-input v-model.trim="form.verificationRuleId" :disabled="isDisabled" style="width: 200px;" :maxLength="16" allowClear />
          <div class="upload_tip">请先设置积分事件，此处填写积分事件ID</div>
          <div v-if="integralName && form.verificationRuleId.length === 16">
            <div class="upload_tip">规则名称: {{ integralName }}</div>
            <div class="upload_tip">积分值: {{ integralValue }}</div>
          </div>
        </div>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { findPointsRuleInfo } from '@/api/activityRegistration'
const options = [
  { label: '2小时', value: '2' },
  { label: '12小时', value: '12' },
  { label: '24小时', value: '24' },
];
export default {
  name: "stepTwo",
  data () {
    return {
      options,
      labelCol: { span: 10 },
      wrapperCol: { span: 8},
      integralName: '',
      integralValue: '',
      // 用户验证方式是否置灰
      disabledVerifyWay: false,
      form: {
        // 费用设置
        costsSet: 1,
        // 是否展示免费
        showFree: 0,
        // 消耗积分数
        consumeIntegralNum: 1,
        // 是否验证参与
        verifyJoin: 1,
        // 验证方式
        verifyWay: 1,
        // 是否需要企业认证
        isEnterpriseAuth: 1,
        // 距离活动几小时前提醒用户
        remindUserTime: [],
        // 参与次数
        joinNum: 1,
        // 核销积分状态 核销积分状态（0：否  1：是）
        verificationIntegralStatus: 0,
        // 核销规则ID
        verificationRuleId: '',
        // 规则积分值
        verificationSendIntegralTotal: '',
      },

      rules: {
        joinNum: [
          {required: true, message: '请输入每人限制参与次数', trigger: 'blur'}
        ],
        verificationIntegralStatus: [
          {required: true, message: '请选择是否发放积分', trigger: 'blur'}
        ],
        verificationRuleId: [
          {required: true, message: '请输入积分规则ID', trigger: 'blur'}
        ],
      }
    }
  },
  props: ['activityInfo', 'isDisabled'],
  watch: {
    activityInfo: {
      handler(newVal, oldVal) {
        if (newVal.costType !== undefined) {
          this.echoData()
        }
      },
      immediate: true
    },
    form: {
      handler(newVal, oldVal) {
        // 当【每人限制参与次数】不为“1”时，如果【是否验证参与】为“是”，则【验证方式】“用户主扫”置灰不可选择，默认为“用户被扫”
        if (this.$route.query.type !== 'view') {
          if (newVal.joinNum !== 1 && newVal.verifyJoin === 1) {
            this.form.verifyWay = 0
            this.disabledVerifyWay = true
          } else {
            this.disabledVerifyWay = false
          }
        }
      },
      deep: true
    },
    'form.verificationRuleId': {
      handler(newVal) {
        if (newVal && newVal.length === 16) {
          this.search();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    echoData() {
      this.form.costsSet = this.activityInfo.costType
      this.form.showFree = this.activityInfo.showFree
      this.form.consumeIntegralNum = this.activityInfo.consumptionPoints
      this.form.verifyJoin = this.activityInfo.whetherOff
      this.form.verifyWay = this.activityInfo.verifyType
      this.form.isEnterpriseAuth = this.activityInfo.whetherEnterprise;
      this.form.joinNum = this.activityInfo.joinNum
      this.form.displayPriority = this.activityInfo.displayPriority
      let newRemindTime = []
      if (this.activityInfo.remindTime !== null || this.activityInfo.remindTime !== '') {
        this.activityInfo.remindTime.split(',').forEach(item => {
          newRemindTime.push(item)
        })
      }
      this.form.remindUserTime = newRemindTime;
      this.form.verificationRuleId = this.activityInfo.verificationRuleId ? this.activityInfo.verificationRuleId : '';
      this.form.verificationIntegralStatus = this.activityInfo.verificationIntegralStatus ? this.activityInfo.verificationIntegralStatus : 0;
      // if (this.form.verificationRuleId) {
      //   this.search();
      // }
    },
    async search() {
      try {
        const res = await findPointsRuleInfo(this.form.verificationRuleId);
        if (res.code === '200') {
          this.integralName = res.data.ruleName;
          this.integralValue = res.data.integralValue
          this.form.verificationSendIntegralTotal = res.data.integralValue;
        } else {
          this.integralName = '';
          this.integralValue = ''
          this.form.verificationSendIntegralTotal = '';
          this.$message.error(res.msg)
        }
      } catch (error) {
        console.log(error);
      }
    },
    onSubmit() {
      if (this.form.costsSet === 1 && this.form.consumeIntegralNum === null) {
        this.$message.error('请输入消耗的积分额度！')
        return;
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$parent.finishedToNext(this.form, 2);
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>
.upload_tip {
  line-height: 20px;
  font-size: 12px;
}
</style>
